import { FadoliWrapper } from './Fadoli.styled';

const Fadoli = () => {
  return (
    <FadoliWrapper>
      <svg
        width="560"
        height="69"
        viewBox="0 0 560 69"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5314_142696)">
          <rect
            width="560"
            height="69"
            fill="transparent"
          />
          <path
            d="M34.9511 0.509766C16.0941 0.509766 6.50212 12.3908 6.28412 26.0158H11.1891C14.2411 21.9828 22.5251 19.0398 33.9701 19.0398C49.2301 19.0398 55.4431 21.7648 55.4431 24.8168C55.4431 26.9968 53.3721 27.6508 45.6331 27.6508H22.5251C9.00912 27.6508 0.943115 35.0628 0.943115 45.7448C0.943115 58.4978 9.55412 67.9808 27.7571 67.9808C39.2021 67.9808 49.7751 62.3128 55.7701 50.5408V66.9998H62.4191V20.5658C61.8741 10.4288 54.4621 0.509766 34.9511 0.509766ZM19.1461 48.2518C10.7531 48.2518 7.04712 46.9438 7.04712 42.5838C7.04712 38.7688 10.6441 37.4608 18.7101 37.4608H43.3441C49.7751 37.4608 53.6991 36.2618 55.7701 34.5178V36.1528C49.6661 47.8158 40.1831 48.2518 19.1461 48.2518Z"
            fill="#9FE5C8"
          />
          <path
            d="M147.254 1.59976L130.25 52.7208L114.881 1.59976H107.687L89.3753 53.1568L72.1533 1.59976H64.9593L86.5413 66.9998H103.436L116.516 31.1388L127.307 66.9998H143.657L166.22 1.59976H147.254Z"
            fill="#9FE5C8"
          />
          <path
            d="M199.964 0.509766C180.889 0.509766 164.975 14.8978 164.975 34.0818C164.975 52.6118 180.126 67.9808 199.201 67.9808C218.058 67.9808 231.138 54.1378 231.574 41.9298H225.579C222.091 46.3988 212.499 47.3798 198.765 47.3798C184.922 47.3798 173.15 46.6168 171.951 37.6788H233.536C235.28 14.8978 219.148 0.509766 199.964 0.509766ZM199.964 19.4758C213.371 19.4758 221.873 21.0018 225.143 27.6508H173.368C176.747 21.0018 185.358 19.4758 199.964 19.4758Z"
            fill="#9FE5C8"
          />
          <path
            d="M271.855 68.6348C289.84 68.6348 301.612 59.5878 301.612 46.8348C301.612 34.5178 297.906 28.4138 275.016 27.9778C256.159 27.9778 246.458 28.0868 246.458 24.0538C246.458 20.8928 250.709 19.5848 270.547 19.8028C279.376 19.8028 288.968 20.2388 292.456 26.0158H298.124C298.124 9.33876 287.442 0.509766 270.983 0.509766C253.325 0.509766 241.662 9.55677 241.662 22.6368C241.662 35.1718 248.638 37.5698 270.874 37.5698C293.328 37.4608 295.944 39.5318 295.944 42.8018C295.944 45.7448 293.437 47.2708 270.874 47.2708C258.557 47.1618 250.491 47.4888 245.804 41.6028H240.136C240.027 58.1708 252.671 68.6348 271.855 68.6348Z"
            fill="#9FE5C8"
          />
          <path
            d="M339.902 0.509766C322.135 0.509766 306.657 13.8078 306.657 33.8638C306.657 54.4648 322.244 67.9808 339.902 67.9808C357.669 67.9808 373.256 54.4648 373.256 33.8638C373.256 13.8078 357.669 0.509766 339.902 0.509766ZM339.902 47.9248C325.514 47.9248 313.306 44.6548 313.306 33.8638C313.306 24.4898 323.77 20.6748 339.902 20.6748C356.143 20.6748 366.607 24.4898 366.607 33.8638C366.607 44.6548 354.399 47.9248 339.902 47.9248Z"
            fill="#9FE5C8"
          />
          <path
            d="M456.422 0.509766C442.797 0.509766 436.693 8.46677 434.077 19.0398C431.679 8.13976 425.466 0.509766 409.879 0.509766C397.998 0.509766 391.785 6.50477 388.733 15.0068V1.59976H381.975V66.9998H388.733V40.7308C388.733 26.4518 392.33 20.5658 409.879 20.5658C423.504 20.5658 428.627 26.3428 428.627 40.5128V66.9998H435.276V40.7308C435.276 26.4518 438.873 20.5658 456.422 20.5658C470.156 20.5658 475.17 26.3428 475.17 40.5128V66.9998H481.819V33.9728C481.819 15.6608 478.44 0.509766 456.422 0.509766Z"
            fill="#9FE5C8"
          />
          <path
            d="M525.368 0.509766C506.293 0.509766 490.379 14.8978 490.379 34.0818C490.379 52.6118 505.53 67.9808 524.605 67.9808C543.462 67.9808 556.542 54.1378 556.978 41.9298H550.983C547.495 46.3988 537.903 47.3798 524.169 47.3798C510.326 47.3798 498.554 46.6168 497.355 37.6788H558.94C560.684 14.8978 544.552 0.509766 525.368 0.509766ZM525.368 19.4758C538.775 19.4758 547.277 21.0018 550.547 27.6508H498.772C502.151 21.0018 510.762 19.4758 525.368 19.4758Z"
            fill="#9FE5C8"
          />
        </g>
        <defs>
          <clipPath id="clip0_5314_142696">
            <rect
              width="560"
              height="69"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </FadoliWrapper>
  );
};

export default Fadoli;
