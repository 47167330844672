import styled from '@emotion/styled';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { mq } from 'styles/mediaqueries.styled';
import { DESKTOP_CARD_GAP, MOBILE_CARD_GAP, TABLET_CARD_GAP } from 'common/data/Constants';

interface Props {
  scaleValue?: number;
  darkMode?: boolean;
}

export const HomeWrapper = styled.div`
  margin-top: 8px;
  /* TABLET */
  ${mq['tablet']} {
    margin-top: 0;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    margin-top: -43px;
  }
  /* DESKTOP_1200 */
  ${mq['desktop_1200']} {
    margin-top: 24px;
  }
`;

export const Headline = styled.h1`
  /* MOBILE */
  font-weight: 700;
  font-size: 8.8vw;
  line-height: 8.533vw;
  letter-spacing: -0.02em;
  margin-bottom: 64px;
  [class^='icon-'],
  [class*=' icon-'] {
    font-size: 6.933vw;
    color: inherit;
    padding-right: 2px;
  }
  .icon-world {
    font-size: 6.25vw;
  }
  span {
    display: inline-block;
    visibility: hidden;
    width: 0;
  }
  /* TABLET */
  ${mq['tablet']} {
    font-size: 8.681vw;
    line-height: 7.917vw;
    margin-bottom: 70px;
    [class^='icon-'],
    [class*=' icon-'] {
      font-size: 6.528vw;
    }
    .icon-world {
      font-size: 6.25vw;
    }
  }
  /* DESKTOP */
  ${mq['desktop']} {
    margin-bottom: 78px;
  }
  /* DESKTOP_1200 */
  ${mq['desktop_1200']} {
    margin-bottom: 166px;
  }
  /* DESKTOP_1440 */
  ${mq['desktop_1440']} {
    font-size: 125px;
    line-height: 114px;
    [class^='icon-'],
    [class*=' icon-'] {
      font-size: 94px;
    }
    .icon-world {
      font-size: 90px;
    }
  }
`;

export const FamilyListContainer = styled.div`
  position: relative;
  /* MOBILE */
  margin-bottom: 120px;
  /* DESKTOP */
  ${mq['desktop']} {
    margin-bottom: 176px;
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  /* MOBILE */
  width: 270px;
  padding-bottom: 24px;
  /* TABLET */
  ${mq['tablet']} {
    width: 347px;
    padding-bottom: 48px;
  }

  .spinner {
    display: none;
    left: -37px;
    top: 7px;
    svg {
      transform: scale(0.9);
    }
    ${mq['tablet']} {
      top: 12px;
      svg {
        transform: scale(1);
      }
    }
    &-visible {
      display: block;
      background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
    }
  }
`;

export const FamilyListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  transform-origin: top left;

  .infinite-scroll-component {
    display: grid;
    overflow: visible !important;
    /* MOBILE */
    grid-template-columns: 1fr;
    row-gap: ${(props: Props) =>
      props.scaleValue ? `${props.scaleValue * MOBILE_CARD_GAP}px` : `${MOBILE_CARD_GAP}px`};
    /* TABLET */
    ${mq['tablet']} {
      grid-template-columns: repeat(2, 1fr);
      column-gap: ${(props: Props) =>
        props.scaleValue ? `${props.scaleValue * TABLET_CARD_GAP}px` : `${TABLET_CARD_GAP}px`};
      row-gap: ${(props: Props) =>
        props.scaleValue ? `${props.scaleValue * TABLET_CARD_GAP}px` : `${TABLET_CARD_GAP}px`};
    }
    /* DESKTOP */
    ${mq['desktop']} {
      grid-template-columns: repeat(3, 1fr);
      column-gap: ${(props: Props) =>
        props.scaleValue ? `${props.scaleValue * DESKTOP_CARD_GAP}px` : `${DESKTOP_CARD_GAP}px`};
      row-gap: ${(props: Props) =>
        props.scaleValue ? `${props.scaleValue * DESKTOP_CARD_GAP}px` : `${DESKTOP_CARD_GAP}px`};
    }
  }
`;

export const FamilyListLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* MOBILE */
  margin-top: ${MOBILE_CARD_GAP}px;
  /* TABLET */
  ${mq['tablet']} {
    margin-top: ${TABLET_CARD_GAP}px;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    margin-top: ${DESKTOP_CARD_GAP}px;
  }
`;

export const EmptyFamilyListWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* MOBILE */
  padding-top: 32px;
  margin: 0 auto 150px;
  /* TABLET */
  ${mq['tablet']} {
    padding-top: 62px;
    margin: 0 auto 200px;
  }
`;

export const EmptyFamilyListText = styled.p`
  font-weight: 700;
  letter-spacing: -0.01em;
  text-align: center;
  /* MOBILE */
  padding-top: 2px;
  font-size: 16px;
  line-height: 18px;
  width: 248px;
  /* TABLET */
  ${mq['tablet']} {
    padding-top: 32px;
    font-size: 20px;
    line-height: 23px;
    width: auto;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    font-size: 24px;
    line-height: 27px;
  }
  /* DESKTOP-1200 */
  ${mq['desktop_1200']} {
    font-size: 32px;
    line-height: 36px;
  }
`;
