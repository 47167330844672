import { PilowlavaWrapper } from './Pilowlava.styled';

const Pilowlava = () => {
  return (
    <PilowlavaWrapper>
      <svg
        width="539"
        height="70"
        viewBox="0 0 539 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5314_142348)">
          <rect
            width="539"
            height="70"
            fill="transparent"
          />
          <path
            d="M36.9761 47.729L14.5301 48.251C6.87411 48.425 0.610107 51.296 0.610107 58.256C0.610107 66.434 6.26511 69.305 11.3111 69.305C18.4451 69.305 24.8831 62.867 28.8851 59.474L37.4111 52.166C40.4561 49.556 42.9791 50.165 42.9791 54.95V59.474C42.9791 64.781 46.3721 69.305 53.5061 69.305C62.4671 69.305 65.4251 61.823 63.5981 52.079L55.6811 10.232C54.5501 4.31603 50.9831 0.575035 45.7631 0.575035C40.1081 0.575035 36.9761 2.83704 29.4071 8.84004L11.9201 22.76C7.91811 25.979 5.30811 29.372 5.30811 33.113C5.30811 38.507 9.48411 42.77 14.6171 42.77C17.8361 42.77 22.7081 41.639 25.4921 35.897L36.0191 14.321C37.3241 11.624 40.4561 5.01204 44.1101 4.31603C47.7641 3.62004 50.1131 6.66503 49.5041 11.45L45.7631 42.248C45.2411 46.685 41.9351 47.642 36.9761 47.729Z"
            fill="#9FE5C8"
          />
          <path
            d="M142.403 16.322L154.844 33.461C155.801 34.766 156.323 35.984 156.323 37.289C156.323 38.855 155.627 40.508 154.496 42.509L151.103 48.773C148.667 53.297 146.666 54.08 144.23 54.08C140.663 54.08 138.749 51.209 137.705 46.772L131.441 19.193C130.136 13.625 127.961 11.885 124.655 11.885C120.74 11.885 117.347 14.843 114.563 19.019L94.7271 48.686C91.9431 52.862 90.4641 54.428 88.2021 54.428C85.0701 54.428 83.5041 52.601 83.5041 49.991C83.5041 47.642 84.7221 45.728 86.8101 42.509L99.4251 22.499C102.644 17.453 104.819 13.016 104.819 9.27504C104.819 4.05504 100.817 0.575035 90.9861 0.575035C76.9791 0.575035 68.4531 17.105 68.4531 29.111C68.4531 31.373 70.4541 32.765 72.0201 32.765C74.8041 32.765 76.4571 31.199 76.8921 27.458L78.9801 11.624C79.4151 8.31803 81.4161 7.01303 83.4171 7.01303C85.5921 7.01303 86.8101 8.92704 86.2011 12.32L78.4581 51.122C78.1101 52.949 78.0231 54.602 78.0231 56.168C78.0231 64.781 83.2431 69.305 90.3771 69.305C93.9441 69.305 98.2071 67.391 101.078 63.389L125.351 29.372C126.221 28.154 127.352 27.023 128.483 27.023C129.875 27.023 130.31 27.98 130.31 28.937C130.31 29.546 130.223 30.416 129.875 31.112L123.35 44.945C116.216 59.996 118.913 69.305 131.876 69.305C150.581 69.305 161.195 44.075 161.195 27.11C161.195 7.10003 157.106 0.575035 150.32 0.575035C143.621 0.575035 139.271 4.49004 139.271 9.36204C139.271 11.537 140.576 13.799 142.403 16.322Z"
            fill="#9FE5C8"
          />
          <path
            d="M222.311 55.559L184.031 58.952C178.985 59.387 176.723 57.038 176.723 54.602C176.723 52.079 178.724 50.339 184.031 49.643L215.177 45.815C221.789 45.032 224.051 41.03 224.051 36.854C224.051 33.635 222.833 29.546 215.177 27.11L181.247 16.322C177.158 15.017 176.462 13.277 176.462 11.102C176.462 9.18803 178.811 5.62104 183.074 7.10003L218.657 19.541C226.139 22.151 230.054 20.063 230.315 14.843C230.576 9.88403 228.14 7.10003 223.529 4.92504C218.483 2.57603 205.433 0.575034 193.775 0.662033C182.204 0.662033 171.764 3.27204 171.764 11.972C171.764 15.191 173.939 18.323 177.854 21.107L203.78 39.116C207.26 41.552 205.52 44.771 201.518 43.118L184.118 35.984C175.592 32.504 168.197 36.68 168.197 44.597C168.197 60.431 187.163 69.392 209.435 69.392C225.008 69.392 230.315 65.825 230.315 60.344C230.315 57.56 228.836 54.95 222.311 55.559Z"
            fill="#9FE5C8"
          />
          <path
            d="M274.28 0.662033C254.357 0.662033 238.609 12.842 238.609 24.935C238.609 29.111 241.045 32.591 246.004 33.461L290.288 41.465C293.072 41.987 294.203 42.77 294.464 44.684C294.725 46.511 293.854 48.164 290.113 48.947L252.616 57.038C249.745 57.647 247.918 57.734 246.526 56.777C245.656 56.168 245.395 54.602 246.439 53.036L250.267 47.294C254.965 40.247 249.832 36.941 246.439 36.941C241.306 36.941 238.957 41.204 238.957 47.381C238.957 63.041 253.835 69.479 268.885 69.479C284.198 69.479 299.684 61.214 299.684 46.076C299.684 42.509 297.334 39.029 293.767 38.159L288.286 36.854C284.806 36.071 283.414 35.636 283.327 32.417L283.241 29.111C283.066 22.847 277.411 17.279 269.146 21.107L250.702 29.633C247.744 31.025 245.047 30.59 244.177 27.371C243.307 24.152 246.439 22.76 250.093 21.02L280.543 6.31703C285.763 3.79404 288.374 5.96903 288.374 10.145V24.935C288.374 28.589 290.983 32.156 295.159 32.156C300.379 32.156 302.293 26.588 302.293 21.803C302.293 11.711 295.16 0.662033 274.28 0.662033Z"
            fill="#9FE5C8"
          />
          <path
            d="M340.224 0.575035C320.04 0.575035 309.426 12.32 309.426 35.114C309.426 57.908 320.04 69.305 340.224 69.305C360.495 69.305 371.109 57.908 371.109 35.114C371.109 12.32 360.495 0.575035 340.224 0.575035ZM364.671 35.897C367.629 39.464 366.759 43.379 362.583 45.032L329.61 58.256C322.65 61.04 317.169 56.516 319.692 48.338L331.437 10.841C332.829 6.31703 338.397 4.22903 341.703 8.14404L364.671 35.897Z"
            fill="#9FE5C8"
          />
          <path
            d="M434.454 42.335C435.411 48.686 434.106 51.47 431.322 51.47C429.669 51.47 427.929 50.426 426.624 47.207L412.53 13.016C408.789 3.96803 403.047 0.488037 396.174 0.488037C386.082 0.488037 380.253 7.27404 380.253 26.849V45.206C380.253 57.821 381.645 69.305 393.042 69.305C402.438 69.305 404.265 60.431 400.611 51.47L388.605 21.977C387.822 20.063 386.691 16.148 386.691 13.712C386.691 8.75303 389.562 5.09903 394.434 5.09903C398.436 5.09903 402.003 7.36103 401.307 11.798L400.524 16.757C399.393 23.978 401.22 31.808 403.83 37.028L413.922 57.038C418.62 66.434 421.839 69.305 425.841 69.305C431.67 69.305 434.454 63.998 437.064 55.298L450.81 9.88403C453.159 2.14103 462.381 4.66404 460.38 11.885L450.549 46.598C449.592 49.991 447.678 55.472 447.678 59.213C447.678 63.65 449.94 69.305 457.248 69.305C462.12 69.305 467.862 66.26 467.775 56.342L467.601 27.371C467.514 6.31703 463.077 0.575035 452.115 0.575035C438.369 0.575035 429.843 10.667 432.192 26.849L434.454 42.335Z"
            fill="#9FE5C8"
          />
          <path
            d="M530.132 14.495C536.657 15.104 538.136 12.494 538.136 9.71004C538.136 4.22904 532.829 0.662033 517.256 0.662033C494.984 0.662033 476.018 9.62304 476.018 25.457C476.018 33.374 483.413 37.55 491.939 34.07L509.339 26.936C513.341 25.283 515.081 28.502 511.601 30.938L485.675 48.947C481.76 51.731 479.585 54.863 479.585 58.082C479.585 66.782 490.025 69.392 501.596 69.392C513.254 69.479 526.304 67.478 531.35 65.129C535.961 62.954 538.397 60.17 538.136 55.211C537.875 49.991 533.96 47.903 526.478 50.513L490.895 62.954C486.632 64.433 484.283 60.866 484.283 58.952C484.283 56.777 484.979 55.037 489.068 53.732L522.998 42.944C530.654 40.508 531.872 36.419 531.872 33.2C531.872 29.024 529.61 25.022 522.998 24.239L491.852 20.411C486.545 19.715 484.544 17.975 484.544 15.452C484.544 13.016 486.806 10.667 491.852 11.102L530.132 14.495Z"
            fill="#9FE5C8"
          />
        </g>
        <defs>
          <clipPath id="clip0_5314_142348">
            <rect
              width="539"
              height="70"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </PilowlavaWrapper>
  );
};

export default Pilowlava;
