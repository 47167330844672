import { IvyPrestoDisplayWrapper } from './IvyPrestoDisplay.styled';

const IvyPrestoDisplay = () => {
  return (
    <IvyPrestoDisplayWrapper>
      <svg
        width="503"
        height="68"
        viewBox="0 0 503 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5314_142522)">
          <rect
            width="503"
            height="68"
            fill="transparent"
          />
          <path
            d="M59.8851 54.1918C58.1631 54.1918 58.7781 51.1168 59.1471 49.5178L69.6021 0.809776H68.8641L58.4091 5.72978C55.2111 2.16277 49.6761 0.0717773 42.1731 0.0717773C16.8351 0.0717773 0.599121 23.9338 0.599121 46.5658C0.599121 59.7268 6.25712 67.4758 15.2361 67.4758C24.2151 67.4758 31.7181 60.5878 37.3761 47.4268L36.8841 49.7638C35.2851 57.2668 35.1621 67.4758 45.9861 67.4758C55.3341 67.4758 62.5911 59.8498 68.9871 47.3038L68.3721 46.8118C64.9281 51.2398 61.6071 54.1918 59.8851 54.1918ZM26.5521 56.1598C24.3381 56.1598 22.7391 52.9618 22.7391 45.4588C22.7391 27.2548 31.9641 1.79378 39.3441 1.79378C42.2961 1.79378 43.6491 5.85278 43.6491 11.3878C43.6491 14.2168 43.2801 17.5378 42.6651 20.8588C39.4671 38.2018 31.5951 56.1598 26.5521 56.1598Z"
            fill="#9FE5C8"
          />
          <path
            d="M162.697 0.0717773L146.338 1.54777V2.28578C149.413 6.09878 150.766 12.4948 150.766 19.5058C150.766 35.2498 143.509 54.6838 135.391 54.6838C132.808 54.6838 131.947 51.9778 131.947 46.6888C131.947 39.0628 134.653 24.9178 140.434 1.30178H139.942L118.417 5.72978L115.342 18.0298C112.513 29.4688 104.641 54.6838 97.9988 54.6838C95.6618 54.6838 95.7848 50.6248 96.6458 47.0578L103.288 17.7838C105.379 8.31278 104.518 0.0717773 95.1698 0.0717773C86.3138 0.0717773 77.9498 7.20578 71.7998 19.7518L72.4148 20.2438C75.8588 15.8158 78.5648 13.3558 80.1638 13.3558C81.7628 13.3558 81.5168 16.0618 81.0248 18.0298L74.3828 46.0738C71.7998 57.0208 73.2758 67.4758 83.8538 67.4758C94.1858 67.4758 104.395 55.0528 109.069 43.9828C107.224 55.0528 108.331 67.4758 117.556 67.4758C133.3 67.4758 167.125 32.0518 167.125 10.7728C167.125 6.46777 165.772 2.77778 162.697 0.0717773Z"
            fill="#9FE5C8"
          />
          <path
            d="M223.478 11.8798C223.478 4.37677 216.59 0.0717773 204.782 0.0717773C175.262 0.0717773 165.053 28.3618 165.053 44.7208C165.053 59.9728 173.909 67.4758 186.578 67.4758C197.156 67.4758 206.996 61.8178 216.59 45.8278L215.852 45.3358C209.948 52.2238 203.675 56.8978 197.771 56.8978C191.621 56.8978 187.685 51.7318 187.685 39.9238C187.685 37.8328 187.808 35.7418 188.054 33.6508C210.317 27.7468 223.478 22.5808 223.478 11.8798ZM202.937 2.03977C205.397 2.03977 206.627 4.74578 206.627 8.43578C206.627 16.6768 201.83 25.2868 188.3 31.3138C190.268 16.5538 197.033 2.03977 202.937 2.03977Z"
            fill="#9FE5C8"
          />
          <path
            d="M236.597 67.4758C252.341 67.4758 262.796 58.0048 262.796 44.1058C262.796 33.5278 256.769 28.7308 251.234 24.1798L247.79 21.3508C244.346 18.5218 239.426 14.4628 239.426 9.17378C239.426 5.11478 242.255 2.53178 246.191 2.53178C252.587 2.53178 260.828 10.4038 264.272 18.2758H265.01L267.962 4.00778C261.32 1.54777 255.047 0.0717773 249.635 0.0717773C235.367 0.0717773 225.65 9.78878 225.65 23.3188C225.65 32.6668 230.324 37.2178 234.629 40.6618L238.073 43.4908C244.223 48.5338 249.02 52.8388 249.02 58.2508C249.02 62.4328 245.945 65.0158 242.009 65.0158C234.137 65.0158 225.281 56.6518 221.837 46.3198H221.099L216.917 61.2028C222.206 65.6308 229.709 67.4758 236.597 67.4758Z"
            fill="#9FE5C8"
          />
          <path
            d="M294.698 67.4758C315.485 67.4758 330.86 48.0418 330.86 26.5168C330.86 10.2808 320.159 0.0717773 304.538 0.0717773C283.751 0.0717773 268.376 19.5058 268.376 41.0308C268.376 57.2668 278.954 67.4758 294.698 67.4758ZM293.345 65.6308C290.024 65.6308 288.794 62.1868 288.794 56.7748C288.794 41.1538 297.527 1.91677 305.891 1.91677C309.212 1.91677 310.442 5.36077 310.442 10.7728C310.442 26.3938 301.709 65.6308 293.345 65.6308Z"
            fill="#9FE5C8"
          />
          <path
            d="M441.653 46.8118C438.209 51.2398 434.765 54.1918 433.166 54.1918C431.321 54.1918 432.059 50.9938 432.428 49.5178L438.947 21.4738C441.899 8.68178 439.439 0.0717773 429.968 0.0717773C415.577 0.0717773 405.491 16.5538 398.357 36.9718L401.678 21.4738C404.384 8.68178 402.17 0.0717773 392.699 0.0717773C378.185 0.0717773 367.976 17.0458 360.965 37.7098L365.147 17.7838C366.746 10.4038 367.607 0.0717773 356.783 0.0717773C347.435 0.0717773 339.686 7.94377 335.012 18.2758L335.627 18.7678C338.579 15.3238 341.039 13.3558 342.146 13.3558C343.868 13.3558 343.253 16.1848 342.884 18.0298L332.798 65.9998H354.938C360.965 37.7098 374.249 13.3558 379.046 13.3558C381.014 13.3558 380.03 17.6608 379.415 20.4898L370.067 65.9998H392.207C398.234 37.7098 411.518 13.3558 416.315 13.3558C418.283 13.3558 417.299 17.6608 416.684 20.4898L410.165 49.7638C408.566 56.8978 408.074 67.4758 418.652 67.4758C428.246 67.4758 435.872 59.8498 442.268 47.3038L441.653 46.8118Z"
            fill="#9FE5C8"
          />
          <path
            d="M502.4 11.8798C502.4 4.37677 495.512 0.0717773 483.704 0.0717773C454.184 0.0717773 443.975 28.3618 443.975 44.7208C443.975 59.9728 452.831 67.4758 465.5 67.4758C476.078 67.4758 485.918 61.8178 495.512 45.8278L494.774 45.3358C488.87 52.2238 482.597 56.8978 476.693 56.8978C470.543 56.8978 466.607 51.7318 466.607 39.9238C466.607 37.8328 466.73 35.7418 466.976 33.6508C489.239 27.7468 502.4 22.5808 502.4 11.8798ZM481.859 2.03977C484.319 2.03977 485.549 4.74578 485.549 8.43578C485.549 16.6768 480.752 25.2868 467.222 31.3138C469.19 16.5538 475.955 2.03977 481.859 2.03977Z"
            fill="#9FE5C8"
          />
        </g>
        <defs>
          <clipPath id="clip0_5314_142522">
            <rect
              width="503"
              height="68"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </IvyPrestoDisplayWrapper>
  );
};

export default IvyPrestoDisplay;
