import { BeastlyWrapper } from './Beastly.styled';

const Beastly = () => {
  return (
    <BeastlyWrapper>
      <svg
        width="564"
        height="69"
        viewBox="0 0 564 69"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5314_142638)">
          <rect
            width="564"
            height="69"
            fill="transparent"
          />
          <path
            d="M36.0571 25.2311C37.7231 25.2311 38.3181 26.3021 38.3181 28.2061V29.8721C38.3181 33.3231 37.1281 33.7991 22.7291 33.7991C7.49708 33.7991 0.476074 39.3921 0.476074 51.5301C0.476074 62.2401 5.23607 68.1901 19.0401 68.1901C33.7961 68.1901 39.0321 60.6931 39.2701 40.1061H40.3411C40.3411 59.0271 43.1971 68.1901 57.7151 68.1901C71.8761 68.1901 74.2561 61.5261 74.2561 45.1041C74.2561 41.7721 74.1371 37.8451 73.8991 33.4421H72.5901C72.8281 42.3671 72.1141 42.6051 70.8051 42.6051C69.7341 42.6051 69.3771 42.2481 69.3771 39.5111V24.7551C69.3771 7.61908 64.3791 0.955078 51.2891 0.955078C38.1991 0.955078 31.8921 5.83408 31.7731 29.5151H30.8211V2.14508H2.73707V30.7051H32.7251C32.9631 27.2541 33.9151 25.2311 36.0571 25.2311ZM33.9151 42.4861C32.0111 42.4861 30.9401 41.5341 30.9401 39.9871C30.9401 36.1791 35.8191 35.4651 38.3181 32.8471V37.8451C38.3181 42.0101 36.4141 42.4861 33.9151 42.4861Z"
            fill="#9FE5C8"
          />
          <path
            d="M166.78 67.0001L169.993 49.1501C171.778 39.3921 174.634 31.5381 177.371 26.6591V2.14508H159.997V26.4211C165.233 30.3481 168.803 37.3691 168.803 46.0561C168.803 46.8891 168.803 47.6031 168.803 48.4361L157.022 37.0121C154.047 34.0371 153.333 32.3711 153.333 30.3481C153.333 28.5631 154.523 27.0161 157.26 26.4211V2.14508H114.658V26.4211C118.228 26.4211 120.37 28.4441 121.322 33.3231L124.297 48.0791L123.821 48.3171L112.04 37.0121C109.065 34.0371 108.351 32.3711 108.351 30.3481C108.351 28.5631 109.541 27.0161 112.278 26.4211V2.14508H73.0084V26.4211C74.7934 26.4211 75.3884 28.6821 76.3404 33.3231L83.0044 67.0001L121.441 66.7621C122.274 61.0501 122.988 55.2191 124.059 50.1021H124.654L127.986 67.0001H166.78Z"
            fill="#9FE5C8"
          />
          <path
            d="M208.986 30.3481C208.986 24.2791 210.414 22.0181 213.032 22.0181C215.531 22.0181 216.959 23.8031 217.078 32.8471H209.105C208.986 32.1331 208.986 31.3001 208.986 30.3481ZM244.448 36.0601H243.258C243.496 40.2251 239.926 42.0101 226.598 42.0101C215.412 42.0101 210.533 40.3441 209.343 34.0371C222.671 34.0371 234.214 34.2751 244.448 34.0371C244.567 31.5381 244.567 29.2771 244.567 26.8971C244.567 12.1411 237.308 0.955078 212.913 0.955078C187.685 0.955078 178.165 10.2371 178.165 34.0371C178.165 62.1211 194.23 68.1901 214.817 68.1901C237.784 68.1901 244.448 58.4321 244.448 36.0601Z"
            fill="#9FE5C8"
          />
          <path
            d="M312.083 50.4591C312.083 40.5821 308.87 33.6801 291.615 33.6801C288.045 33.6801 280.191 34.0371 279.001 34.0371C271.623 34.0371 270.671 33.2041 270.671 31.5381C270.671 30.1101 272.575 28.9201 276.978 28.9201C280.548 28.9201 284.237 29.7531 285.07 32.4901H309.941V2.14508H286.141V30.4671H284.951C283.166 6.31007 278.049 1.07408 263.769 1.07408C251.393 1.07408 246.633 9.52308 246.633 18.0911C246.633 27.6111 250.679 35.5841 264.721 35.5841C269.838 35.5841 275.312 35.2271 280.667 35.2271C284.594 35.2271 285.903 36.2981 285.903 37.7261C285.903 39.1541 284.118 40.3441 279.953 40.3441C276.145 40.3441 272.099 39.5111 271.266 36.7741H246.395V67.0001H269.957V38.7971H271.147C272.813 63.0731 278.406 68.1901 292.686 68.1901C307.085 68.1901 312.083 59.8601 312.083 50.4591Z"
            fill="#9FE5C8"
          />
          <path
            d="M382.35 34.0371C382.35 10.2371 373.068 0.955078 347.602 0.955078C322.136 0.955078 312.854 9.88008 312.854 34.0371C312.854 59.1461 322.136 68.1901 347.602 68.1901C373.068 68.1901 382.35 59.1461 382.35 34.0371ZM352.481 33.9181C352.481 44.1521 351.41 46.8891 347.602 46.8891C343.794 46.8891 342.723 44.1521 342.723 33.9181C342.723 24.1601 343.794 22.1371 347.602 22.1371C351.41 22.1371 352.481 24.1601 352.481 33.9181Z"
            fill="#9FE5C8"
          />
          <path
            d="M420.876 42.4861C418.496 42.4861 418.02 40.3441 418.02 35.3461V30.5861C418.02 26.8971 419.448 25.7071 421.709 25.7071C424.446 25.7071 425.398 26.7781 425.398 31.4191V35.3461C425.398 40.3441 424.922 42.4861 422.542 42.4861V67.0001H458.123V42.4861C455.743 42.4861 455.267 40.3441 455.267 35.3461V30.5861C455.267 26.8971 456.695 25.7071 458.956 25.7071C461.693 25.7071 462.645 26.7781 462.645 31.4191V35.3461C462.645 40.3441 462.169 42.4861 459.789 42.4861V67.0001H495.37V42.4861C492.99 42.4861 492.514 40.3441 492.514 35.3461V23.3271C492.514 7.61908 487.873 0.955078 473.712 0.955078C459.908 0.955078 454.077 4.52507 454.196 28.4441H453.244C452.887 9.16607 448.484 0.955078 435.87 0.955078C422.661 0.955078 416.83 4.40608 416.949 28.4441H415.878V2.14508H384.105V26.5401C387.556 26.5401 388.151 28.2061 388.151 33.6801V35.3461C388.151 40.3441 387.556 42.4861 384.105 42.4861V67.0001H420.876V42.4861Z"
            fill="#9FE5C8"
          />
          <path
            d="M527.521 30.3481C527.521 24.2791 528.949 22.0181 531.567 22.0181C534.066 22.0181 535.494 23.8031 535.613 32.8471H527.64C527.521 32.1331 527.521 31.3001 527.521 30.3481ZM562.983 36.0601H561.793C562.031 40.2251 558.461 42.0101 545.133 42.0101C533.947 42.0101 529.068 40.3441 527.878 34.0371C541.206 34.0371 552.749 34.2751 562.983 34.0371C563.102 31.5381 563.102 29.2771 563.102 26.8971C563.102 12.1411 555.843 0.955078 531.448 0.955078C506.22 0.955078 496.7 10.2371 496.7 34.0371C496.7 62.1211 512.765 68.1901 533.352 68.1901C556.319 68.1901 562.983 58.4321 562.983 36.0601Z"
            fill="#9FE5C8"
          />
        </g>
        <defs>
          <clipPath id="clip0_5314_142638">
            <rect
              width="564"
              height="69"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </BeastlyWrapper>
  );
};

export default Beastly;
