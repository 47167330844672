import { SwearDisplayWrapper } from './SwearDisplay.styled';

const SwearDisplay = () => {
  return (
    <SwearDisplayWrapper>
      <svg
        width="528"
        height="71"
        viewBox="0 0 528 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5314_142754)">
          <rect
            width="528"
            height="71"
            fill="transparent"
          />
          <path
            d="M52.248 67.7682C60.696 67.7682 67.608 58.1682 68.504 37.0482H66.456C65.688 49.8482 64.664 52.9202 62.488 52.9202C60.568 52.9202 60.056 51.0002 60.056 38.3282C60.056 32.6962 60.44 23.3522 60.44 17.4642C60.44 7.22422 52.504 1.59222 39.96 1.59222C24.728 1.59222 0.919992 12.7282 2.45599 32.1842L24.344 29.2402C23.832 24.6322 23.32 17.7202 23.32 13.8802C23.32 10.2962 24.216 7.99222 27.672 7.99222C34.584 7.99222 36.504 10.2962 36.76 32.1842C14.872 37.4322 0.791992 44.3442 0.791992 52.0242C0.791992 59.8322 6.03999 68.2802 16.664 68.2802C24.472 68.2802 33.048 60.4722 36.248 52.0242H37.4C37.656 59.4482 40.984 67.7682 52.248 67.7682ZM30.872 52.0242C24.984 52.0242 22.168 48.8242 22.168 42.8082C22.168 38.9682 23.832 38.0722 26.776 37.3042C29.848 36.4082 33.432 35.8962 36.76 35.2562C36.76 36.1522 36.76 37.0482 36.76 38.0722C36.76 43.0642 36.76 46.1362 36.12 48.9522C35.736 50.7442 34.456 52.0242 30.872 52.0242Z"
            fill="#9FE5C8"
          />
          <path
            d="M116.117 5.30422C118.677 5.30422 119.317 6.45622 119.189 9.14422C119.061 11.5762 118.421 13.8802 117.397 16.3122C115.093 12.4722 113.173 9.01622 113.173 7.48022C113.301 5.94422 114.197 5.30422 116.117 5.30422ZM112.149 37.6882C116.629 46.9042 123.285 63.8002 126.101 70.8402H128.661C149.141 27.4482 156.437 10.2962 160.021 7.09622L161.301 5.94422V2.87222H130.837V5.94422L134.037 6.58422C137.877 7.48022 140.181 11.3202 140.437 18.6162C140.565 26.5522 138.773 38.3282 136.597 46.9042C133.141 41.2722 126.357 30.6482 122.517 24.3762C121.237 22.4562 119.829 20.1522 118.421 17.9762C121.621 11.8322 124.437 7.73622 126.357 6.71222L128.021 5.94422V2.87222H95.381V5.94422L96.021 6.45622C98.965 8.63222 102.677 16.9522 109.589 32.1842C107.029 37.4322 104.725 41.7842 102.805 45.6242C100.885 41.9122 97.045 33.7202 95.253 30.5202C91.029 22.3282 87.317 15.2882 87.317 11.5762C87.317 8.76022 88.981 6.71222 91.797 6.07222L92.565 5.94422V2.87222H59.541V5.94422L60.693 6.84022C64.021 9.65622 68.373 19.2562 76.693 37.8162C81.173 47.2882 88.725 63.6722 91.925 70.8402H94.613C100.117 58.8082 104.981 47.0322 110.613 34.4882C111.125 35.5122 111.637 36.5362 112.149 37.6882Z"
            fill="#9FE5C8"
          />
          <path
            d="M183.889 6.20022C189.649 6.20022 194.897 12.9842 194.897 21.5602C194.897 23.6082 194.001 24.5042 192.081 24.7602L178.385 27.0642C178.385 9.14422 179.281 6.20022 183.889 6.20022ZM191.953 1.72021C168.529 1.72021 153.809 13.7522 153.809 35.7682C153.809 58.8082 167.249 68.4082 185.297 68.4082C207.697 68.4082 212.561 52.9202 216.657 39.6082L214.609 37.5602C210.641 47.5442 204.497 50.7442 195.409 50.7442C184.913 50.7442 178.385 47.2882 178.385 28.4722V28.0882L195.537 28.7282L216.401 25.6562C216.529 10.5522 211.281 1.72021 191.953 1.72021Z"
            fill="#9FE5C8"
          />
          <path
            d="M249.74 0.824219C233.484 0.824219 218.252 11.9602 218.252 25.6562C218.252 31.9282 220.428 39.3522 238.22 43.5762C258.188 48.4402 262.284 51.5122 262.284 56.2482C262.284 58.8082 261.388 60.3442 256.396 60.3442C239.884 60.3442 230.412 56.6322 225.676 46.3922L223.244 41.1442H219.66C219.404 49.7202 219.02 56.6322 218.508 63.2882C222.86 65.8482 229.516 68.2802 239.884 68.2802C261.388 68.2802 272.396 57.5282 272.396 43.9602C272.396 35.0002 269.196 27.8322 252.3 23.9922C237.196 20.6642 234.38 16.3122 234.38 11.7042C234.38 7.73622 237.58 5.56022 241.676 5.56022C250.38 5.56022 258.316 13.7522 262.412 21.4322L265.1 26.1682H268.684C268.812 17.4642 269.324 8.88822 269.836 3.38422C265.356 2.10422 258.444 0.824219 249.74 0.824219Z"
            fill="#9FE5C8"
          />
          <path
            d="M298.771 28.8562C298.771 7.86422 300.307 5.68822 303.635 5.68822C311.955 5.68822 319.251 18.7442 319.251 44.4722C319.251 61.2402 318.227 63.9282 314.515 63.9282C304.531 63.9282 298.771 53.8162 298.771 28.8562ZM343.699 30.5202C343.699 12.3442 333.331 1.59222 312.083 1.59222C286.611 1.59222 274.067 13.6242 274.067 35.7682C274.067 55.6082 286.739 68.2802 305.811 68.2802C329.363 68.2802 343.699 55.4802 343.699 30.5202Z"
            fill="#9FE5C8"
          />
          <path
            d="M416.535 26.0402C416.535 20.0242 420.247 18.4882 424.727 18.4882C431.895 18.4882 433.815 20.6642 433.815 38.7122C433.815 59.3202 434.071 61.6242 429.719 62.6482L427.287 63.2882V67.0002H464.279V63.2882L462.231 62.7762C457.495 61.6242 457.367 61.6242 457.367 41.6562C457.367 33.9762 457.623 25.1442 457.623 17.4642C457.623 7.09622 450.967 1.59222 441.495 1.59222C429.079 1.59222 418.327 12.2162 416.151 21.0482L415.383 20.9202C415.383 19.8962 415.383 18.7442 415.383 17.5922C415.383 7.09622 409.239 1.59222 400.151 1.59222C387.863 1.59222 377.239 12.2162 375.191 21.0482H374.167V2.36022L372.375 1.72021C365.719 4.40822 354.071 7.60822 345.239 9.27222V12.3442L349.463 14.3922C352.279 15.8002 352.279 16.9522 352.279 39.9922C352.279 59.3202 352.151 60.8562 347.287 62.6482L345.239 63.2882V67.0002H383.127V63.2882L380.055 62.3922C375.703 61.2402 375.575 61.1122 375.575 26.0402C375.575 20.0242 379.415 18.4882 383.767 18.4882C390.935 18.4882 392.983 20.6642 392.983 38.7122C392.983 59.3202 393.111 61.6242 388.759 62.6482L386.327 63.2882V67.0002H423.959V63.2882L421.271 62.7762C416.535 61.8802 416.535 61.6242 416.535 26.0402Z"
            fill="#9FE5C8"
          />
          <path
            d="M494.649 6.20022C500.409 6.20022 505.657 12.9842 505.657 21.5602C505.657 23.6082 504.761 24.5042 502.841 24.7602L489.145 27.0642C489.145 9.14422 490.041 6.20022 494.649 6.20022ZM502.713 1.72021C479.289 1.72021 464.569 13.7522 464.569 35.7682C464.569 58.8082 478.009 68.4082 496.057 68.4082C518.457 68.4082 523.321 52.9202 527.417 39.6082L525.369 37.5602C521.401 47.5442 515.257 50.7442 506.169 50.7442C495.673 50.7442 489.145 47.2882 489.145 28.4722V28.0882L506.297 28.7282L527.161 25.6562C527.289 10.5522 522.041 1.72021 502.713 1.72021Z"
            fill="#9FE5C8"
          />
        </g>
        <defs>
          <clipPath id="clip0_5314_142754">
            <rect
              width="528"
              height="71"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </SwearDisplayWrapper>
  );
};

export default SwearDisplay;
