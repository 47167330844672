import { NanJauneWrapper } from './NanJaune.styled';

const NanJaune = () => {
  return (
    <NanJauneWrapper>
      <svg
        width="585"
        height="71"
        viewBox="0 0 585 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5314_142464)">
          <rect
            width="585"
            height="71"
            fill="transparent"
          />
          <path
            d="M34.2251 0.0151367C45.7751 0.0151367 54.1751 2.37764 59.4251 7.10263C64.6751 11.8276 67.3001 18.9501 67.3001 28.4701V69.1051H47.7701L49.3451 47.8951L48.2951 47.7901C46.1251 55.2801 42.5901 60.9851 37.6901 64.9051C32.7901 68.8251 27.0151 70.7851 20.3651 70.7851C14.3101 70.7851 9.65508 69.5251 6.40008 67.0051C3.18008 64.4851 1.57008 60.7751 1.57008 55.8751C1.57008 51.5701 2.93508 48.2276 5.66508 45.8476C8.39508 43.4326 12.5601 41.7876 18.1601 40.9126V39.8626L1.88508 37.9201C1.29008 36.1701 0.852578 34.5776 0.572578 33.1426C0.327578 31.6726 0.205078 29.9401 0.205078 27.9451C0.205078 22.8001 1.43008 18.1276 3.88008 13.9276C6.36508 9.69263 10.1626 6.31513 15.2726 3.79513C20.3826 1.27514 26.7001 0.0151367 34.2251 0.0151367ZM34.0151 16.7101C31.1801 16.7101 28.6426 17.2351 26.4026 18.2851C24.1626 19.3351 22.3951 20.8751 21.1001 22.9051C19.8401 24.9001 19.2101 27.3151 19.2101 30.1501C19.2101 32.1101 19.4201 33.8776 19.8401 35.4526C20.2951 36.9926 20.9951 38.4976 21.9401 39.9676L26.9276 38.6551C32.1776 37.2551 36.1676 36.1176 38.8976 35.2426C41.6276 34.3326 43.8326 33.3001 45.5126 32.1451C47.2276 30.9551 48.0851 29.5551 48.0851 27.9451C48.0851 23.9901 46.8951 21.1376 44.5151 19.3876C42.1351 17.6026 38.6351 16.7101 34.0151 16.7101ZM27.6101 57.2401C30.3751 57.2401 32.8951 56.8726 35.1701 56.1376C37.4451 55.3676 39.6501 54.0376 41.7851 52.1476C43.9201 50.2226 46.0201 47.5626 48.0851 44.1676V34.7701L47.1926 34.4026C45.8976 35.4176 44.0951 36.3801 41.7851 37.2901C39.5101 38.1651 36.2551 39.1451 32.0201 40.2301C28.5201 41.1051 25.8251 41.9976 23.9351 42.9076C22.0451 43.8176 20.7151 44.8501 19.9451 46.0051C19.1751 47.1601 18.7901 48.5951 18.7901 50.3101C18.7901 52.4451 19.5251 54.1426 20.9951 55.4026C22.4651 56.6276 24.6701 57.2401 27.6101 57.2401Z"
            fill="#9FE5C8"
          />
          <path
            d="M155.877 53.9851L168.687 1.80013H188.847L170.787 69.0001H140.757L128.315 22.1701H127.58L115.137 69.0001H85.1074L67.0474 1.80013H87.1024L100.07 53.9851H101.277L115.032 1.80013H140.967L154.67 53.9851H155.877Z"
            fill="#9FE5C8"
          />
          <path
            d="M257.503 36.0301C257.713 37.5351 257.818 39.0576 257.818 40.5976C257.818 46.1626 256.505 51.2201 253.88 55.7701C251.255 60.3201 247.3 63.9426 242.015 66.6376C236.73 69.3326 230.308 70.6801 222.748 70.6801C210.673 70.6801 201.59 67.7751 195.5 61.9651C189.445 56.1201 186.418 47.0551 186.418 34.7701C186.418 27.3501 188.01 21.0151 191.195 15.7651C194.38 10.5151 198.51 6.57763 203.585 3.95263C208.66 1.32764 213.998 0.0151367 219.598 0.0151367C229.643 0.0151367 237.675 2.34263 243.695 6.99763C249.715 11.6176 253.023 18.7051 253.618 28.2601L240.598 32.3026V33.5626L257.503 36.0301ZM219.703 15.3451C215.643 15.3451 212.3 16.8326 209.675 19.8076C207.05 22.7826 205.738 27.7701 205.738 34.7701C205.738 36.2751 205.825 37.8151 206 39.3901L233.983 30.6751C233.528 25.1801 232.04 21.2601 229.52 18.9151C227.035 16.5351 223.763 15.3451 219.703 15.3451ZM222.853 54.5101C225.793 54.5101 228.47 53.9501 230.885 52.8301C233.3 51.6751 235.225 49.9776 236.66 47.7376C238.095 45.4626 238.813 42.7151 238.813 39.4951C238.813 38.3751 238.69 37.3601 238.445 36.4501C238.2 35.5051 237.833 34.4726 237.343 33.3526L206.63 42.9076C207.68 47.0026 209.57 49.9601 212.3 51.7801C215.065 53.6001 218.583 54.5101 222.853 54.5101Z"
            fill="#9FE5C8"
          />
          <path
            d="M306.006 31.8301C311.466 33.0901 315.509 35.0676 318.134 37.7626C320.759 40.4576 322.071 44.0801 322.071 48.6301C322.071 56.0851 319.429 61.6326 314.144 65.2726C308.894 68.8776 300.966 70.6801 290.361 70.6801C283.851 70.6801 278.286 69.8226 273.666 68.1076C269.046 66.3926 265.546 64.0126 263.166 60.9676C260.786 57.9226 259.596 54.4401 259.596 50.5201C259.596 48.4901 259.946 46.3201 260.646 44.0101L273.561 41.7001V40.4401C268.521 39.0051 264.899 36.8176 262.694 33.8776C260.489 30.9376 259.386 26.8951 259.386 21.7501C259.386 17.0951 260.629 13.1401 263.114 9.88514C265.634 6.63013 269.116 4.18013 273.561 2.53514C278.041 0.855138 283.186 0.0151367 288.996 0.0151367C298.586 0.0151367 306.041 1.87014 311.361 5.58014C316.716 9.29014 319.516 15.0651 319.761 22.9051C319.761 24.4101 319.656 25.8451 319.446 27.2101L306.006 30.6751V31.8301ZM287.841 13.9801C284.131 13.9801 281.191 14.6626 279.021 16.0276C276.851 17.3576 275.766 19.3001 275.766 21.8551C275.766 23.5351 276.291 24.8826 277.341 25.8976C278.391 26.9126 280.316 27.7701 283.116 28.4701C285.951 29.1701 290.116 29.8001 295.611 30.3601C297.781 30.5001 299.951 30.7451 302.121 31.0951C302.541 29.4501 302.751 27.9451 302.751 26.5801C302.751 22.1701 301.386 18.9676 298.656 16.9726C295.961 14.9776 292.356 13.9801 287.841 13.9801ZM291.516 57.1351C296.031 57.1351 299.391 56.5576 301.596 55.4026C303.836 54.2126 304.956 52.2701 304.956 49.5751C304.956 48.0351 304.396 46.8451 303.276 46.0051C302.191 45.1651 300.249 44.4826 297.449 43.9576C294.649 43.4326 290.326 42.8901 284.481 42.3301C282.311 42.1201 280.176 41.8226 278.076 41.4376C277.096 43.8176 276.606 45.9351 276.606 47.7901C276.606 50.9051 277.761 53.2501 280.071 54.8251C282.381 56.3651 286.196 57.1351 291.516 57.1351Z"
            fill="#9FE5C8"
          />
          <path
            d="M359.627 70.7851C347.727 70.7851 338.802 67.8626 332.852 62.0176C326.902 56.1376 323.927 47.0551 323.927 34.7701C323.927 27.2801 325.537 20.9276 328.757 15.7126C331.977 10.4626 336.229 6.54263 341.514 3.95263C346.834 1.32764 352.592 0.0151367 358.787 0.0151367C370.862 0.0151367 379.892 2.95514 385.877 8.83514C391.897 14.7151 394.907 23.8151 394.907 36.1351C394.907 43.4501 393.314 49.7151 390.129 54.9301C386.979 60.1451 382.727 64.1001 377.372 66.7951C372.017 69.4551 366.102 70.7851 359.627 70.7851ZM359.837 54.4051C363.127 54.4051 365.909 53.8976 368.184 52.8826C370.459 51.8326 372.244 49.9601 373.539 47.2651C374.834 44.5701 375.482 40.8601 375.482 36.1351C375.482 29.2401 373.942 24.2701 370.862 21.2251C367.817 18.1451 363.862 16.6051 358.997 16.6051C355.672 16.6051 352.872 17.1301 350.597 18.1801C348.322 19.1951 346.519 21.0326 345.189 23.6926C343.894 26.3526 343.247 30.0451 343.247 34.7701C343.247 41.7351 344.787 46.7576 347.867 49.8376C350.947 52.8826 354.937 54.4051 359.837 54.4051Z"
            fill="#9FE5C8"
          />
          <path
            d="M487.288 0.0151367C494.393 0.0151367 499.818 2.71013 503.563 8.10013C507.343 13.4901 509.233 22.1351 509.233 34.0351V69.0001H489.913V35.6101C489.913 30.9551 489.475 27.2451 488.6 24.4801C487.725 21.7151 486.413 19.7376 484.663 18.5476C482.913 17.3226 480.673 16.7101 477.943 16.7101C475.283 16.7101 472.903 17.5151 470.803 19.1251C468.703 20.7001 467.058 23.0101 465.868 26.0551C464.678 29.1001 464.083 32.7051 464.083 36.8701V69.0001H444.658V35.6101C444.658 30.9201 444.22 27.2101 443.345 24.4801C442.505 21.7151 441.21 19.7376 439.46 18.5476C437.71 17.3226 435.453 16.7101 432.688 16.7101C430.028 16.7101 427.648 17.5151 425.548 19.1251C423.448 20.7001 421.803 23.0101 420.613 26.0551C419.423 29.1001 418.828 32.7051 418.828 36.8701V69.0001H399.508V1.80013H418.828L417.043 26.7901L417.988 26.8951C419.003 19.8601 420.56 14.4001 422.66 10.5151C424.76 6.59513 427.368 3.86513 430.483 2.32513C433.633 0.785134 437.518 0.0151367 442.138 0.0151367C448.193 0.0151367 452.988 2.15014 456.523 6.42014C460.058 10.6551 461.983 17.4801 462.298 26.8951H463.348C464.363 19.8601 465.903 14.4001 467.968 10.5151C470.068 6.59513 472.675 3.86513 475.79 2.32513C478.905 0.785134 482.738 0.0151367 487.288 0.0151367Z"
            fill="#9FE5C8"
          />
          <path
            d="M584.303 36.0301C584.513 37.5351 584.618 39.0576 584.618 40.5976C584.618 46.1626 583.305 51.2201 580.68 55.7701C578.055 60.3201 574.1 63.9426 568.815 66.6376C563.53 69.3326 557.108 70.6801 549.548 70.6801C537.473 70.6801 528.39 67.7751 522.3 61.9651C516.245 56.1201 513.218 47.0551 513.218 34.7701C513.218 27.3501 514.81 21.0151 517.995 15.7651C521.18 10.5151 525.31 6.57763 530.385 3.95263C535.46 1.32764 540.798 0.0151367 546.398 0.0151367C556.443 0.0151367 564.475 2.34263 570.495 6.99763C576.515 11.6176 579.823 18.7051 580.418 28.2601L567.398 32.3026V33.5626L584.303 36.0301ZM546.503 15.3451C542.443 15.3451 539.1 16.8326 536.475 19.8076C533.85 22.7826 532.538 27.7701 532.538 34.7701C532.538 36.2751 532.625 37.8151 532.8 39.3901L560.783 30.6751C560.328 25.1801 558.84 21.2601 556.32 18.9151C553.835 16.5351 550.563 15.3451 546.503 15.3451ZM549.653 54.5101C552.593 54.5101 555.27 53.9501 557.685 52.8301C560.1 51.6751 562.025 49.9776 563.46 47.7376C564.895 45.4626 565.613 42.7151 565.613 39.4951C565.613 38.3751 565.49 37.3601 565.245 36.4501C565 35.5051 564.633 34.4726 564.143 33.3526L533.43 42.9076C534.48 47.0026 536.37 49.9601 539.1 51.7801C541.865 53.6001 545.383 54.5101 549.653 54.5101Z"
            fill="#9FE5C8"
          />
        </g>
        <defs>
          <clipPath id="clip0_5314_142464">
            <rect
              width="585"
              height="71"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </NanJauneWrapper>
  );
};

export default NanJaune;
