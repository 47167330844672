import styled from '@emotion/styled';
import { GREY_6 } from 'design-system/global/colors';
import { mq } from 'styles/mediaqueries.styled';
import { easeInOutBack, easeInOutExpo } from 'common/easing/Easing';

interface Props {
  counter: number;
}

const GREEN = '#7EE0B8';

export const FontSliderStyled = styled.div`
  position: relative;
  display: inline-flex;
  /* MOBILE */
  height: 5.556vw;
  /* DESKTOP_1440 */
  ${mq['desktop_1440']} {
    height: 80px;
  }
`;

export const FontSliderWrapper = styled.div`
  position: relative;
  display: inline-flex;
  overflow: hidden;
  /* MOBILE */
  height: 6.25vw;
  top: -0.694vw;
  /* DESKTOP_1440 */
  ${mq['desktop_1440']} {
    height: 90px;
    top: -10px;
  }
`;

export const FontSliderContainer = styled.div`
  position: relative;
  transition: transform ${(props: Props) => (props.counter === 0 ? '0s' : '0.8s')} ${easeInOutExpo};
  /* MOBILE */
  top: 0.694vw;
  transform: translateY(calc(${(props: Props) => props.counter * -6.25}vw));
  /* DESKTOP_1440 */
  ${mq['desktop_1440']} {
    top: 10px;
    transform: translateY(calc(${(props: Props) => props.counter * -90}px));
  }

  svg {
    path {
      fill: ${GREEN};
    }
  }

  &.resize-animation-stopper {
    transition: none;
  }
`;

export const FontLinkButton = styled.div`
  /* MOBILE */
  display: none;
  /* TABLET */
  ${mq['tablet']} {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: ${GREEN};
    top: -21px;
    width: 42px;
    height: 42px;
    transform: translate(37.431vw);
    transition: transform 0.8s ${easeInOutBack};

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    [class^='icon-'],
    [class*=' icon-'] {
      font-size: 16px;
      color: ${GREY_6};
    }

    @media (hover: hover) {
      .icon-roll-1 {
        transition: transform 0.2s ${easeInOutExpo};
        transform: translateX(42px);
      }
      .icon-roll-2 {
        transition: none;
        transform: translateX(-42px);
      }
      .icon-roll-3 {
        transition: transform 0.2s ${easeInOutExpo};
        transform: translateX(0);
      }
    }
  }
  /* DESKTOP_1440 */
  ${mq['desktop_1440']} {
    transform: translate(539px);
  }

  &.resize-animation-stopper {
    transition: none;
  }
`;
