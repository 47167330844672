import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Link from 'next/link';
import { Icon } from 'design-system/atoms/icon';
import Pilowlava from './Pilowlava';
import Newsreader from './Newsreader';
import NanJaune from './NanJaune';
import IvyPrestoDisplay from './IvyPrestoDisplay';
import AfricaRainfall from './AfricaRainfall';
import Beastly from './Beastly';
import Fadoli from './Fadoli';
import SwearDisplay from './SwearDisplay';
import { FontLinkButton, FontSliderContainer, FontSliderStyled, FontSliderWrapper } from './HomeHeadlineSlider.styled';

const HomeHeadlineSlider = () => {
  const [counter, setCounter] = useState<number>(0);

  const second = useMemo(() => 2, []);
  const nbFonts = useMemo(() => 8, []);
  const fontsLinks = useMemo(
    () => [
      '/font/Studio-triple-Pilowlava-89042',
      '/font/Production-Type-Newsreader-Display-136417',
      '/font/NaN-NaN-Jaune-Maxi-504221',
      '/font/The-Ivy-Foundry-IvyPresto-Display-112610',
      '/font/newglyph-Africa-Rainfall-537774',
      '/font/OH-no-Type-Co-Beastly-72-Point-89822',
      '/font/Pizza-Typefaces-Fadoli-868466',
      '/font/OH-no-Type-Co-Swear-Display-189564',
      '/font/Studio-triple-Pilowlava-89042',
    ],
    []
  );

  const fontSliderContainerRef = useRef<HTMLDivElement>(null);
  const fontLinkButtonRef = useRef<HTMLDivElement>(null);
  const requestRef = useRef<number>(0);
  const previousTimerRef = useRef<number>(0);
  const startAnimationRef = useRef<boolean>(false);
  const animatingRef = useRef<boolean>(false);
  const resizeTimerRef = useRef<NodeJS.Timeout>();

  const calculateCurrentFontWidth = useCallback((n: number) => {
    const currentFontEl = fontSliderContainerRef.current?.querySelectorAll('a div')[n] as HTMLDivElement;
    if (currentFontEl) {
      const fontWidth = (currentFontEl.querySelector('svg') as SVGElement).clientWidth;
      if (fontLinkButtonRef.current) {
        fontLinkButtonRef.current.style.transform = `translateX(${fontWidth}px)`;
      }
    }
  }, []);

  const playAnimation = useCallback(
    (time: number) => {
      if (counter === 0 && startAnimationRef.current && !animatingRef.current) {
        // Loop to first font
        previousTimerRef.current = time;
        const n = counter + 1;
        setCounter(n);
        animatingRef.current = false;
      } else if (Date.now() - previousTimerRef.current >= second * 1000 && !animatingRef.current) {
        if (!startAnimationRef.current) startAnimationRef.current = true;
        previousTimerRef.current = time;
        const n = counter === nbFonts ? 0 : counter + 1;
        setCounter(n);
        if (n === 0) {
          // no transition when n === 0, thats why we simulate `animationCompleted` function
          animatingRef.current = false;
        } else {
          animatingRef.current = true;
        }
        // Calculate current font width
        calculateCurrentFontWidth(n);
      }
      requestRef.current = requestAnimationFrame(playAnimation);
    },
    [calculateCurrentFontWidth, counter, nbFonts, second]
  );

  const animationCompleted = useCallback(() => {
    animatingRef.current = false;
  }, []);

  const handleRollOver = useCallback(() => {
    if (requestRef.current) {
      cancelAnimationFrame(requestRef.current);
    }
    if (fontLinkButtonRef.current) {
      const iconEl = fontLinkButtonRef.current.querySelector('.icon-right') as HTMLElement;
      if (iconEl) {
        iconEl.classList.add('icon-roll-1');
        iconEl.addEventListener('transitionend', () => {
          if (iconEl.classList.contains('icon-roll-1')) {
            iconEl.classList.remove('icon-roll-1');
            iconEl.classList.add('icon-roll-2');
            setTimeout(() => {
              iconEl.classList.remove('icon-roll-2');
              iconEl.classList.add('icon-roll-3');
            }, 0);
          }
          if (iconEl.classList.contains('icon-roll-3')) {
            iconEl.classList.remove('icon-roll-3');
          }
        });
      }
    }
  }, []);

  const handleRollOut = useCallback(() => {
    requestRef.current = requestAnimationFrame(playAnimation);
  }, [playAnimation]);

  const handleClick = useCallback(() => {
    if (requestRef.current) {
      cancelAnimationFrame(requestRef.current);
    }
  }, []);

  const handleResize = useCallback(() => {
    if (fontSliderContainerRef.current) {
      // Stop animation/transition on resize
      fontSliderContainerRef.current.classList.add('resize-animation-stopper');
      fontLinkButtonRef.current?.classList.add('resize-animation-stopper');
      // Calculate current font width
      calculateCurrentFontWidth(counter);
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
      if (resizeTimerRef.current) {
        clearTimeout(resizeTimerRef.current);
      }
      // Reset animation/transition on resize
      resizeTimerRef.current = setTimeout(() => {
        if (fontSliderContainerRef.current) {
          fontSliderContainerRef.current.classList.remove('resize-animation-stopper');
          fontLinkButtonRef.current?.classList.remove('resize-animation-stopper');
          animatingRef.current = false;
          requestRef.current = requestAnimationFrame(playAnimation);
        }
      }, 200);
    }
  }, [calculateCurrentFontWidth, counter, playAnimation]);

  useEffect(() => {
    previousTimerRef.current = Date.now();
    requestRef.current = requestAnimationFrame(playAnimation);
    // Calculate current font width
    calculateCurrentFontWidth(counter);
    if (fontSliderContainerRef.current) {
      fontSliderContainerRef.current.addEventListener('transitionend', animationCompleted);
    }
    window.addEventListener('resize', handleResize);

    return () => {
      cancelAnimationFrame(requestRef.current);
      window.removeEventListener('resize', handleResize);
    };
  }, [animationCompleted, calculateCurrentFontWidth, counter, handleResize, playAnimation]);

  return (
    <FontSliderStyled>
      <FontSliderWrapper>
        <FontSliderContainer
          ref={fontSliderContainerRef}
          counter={counter}
        >
          <Link
            href={fontsLinks[counter]}
            prefetch={false}
            onMouseEnter={handleRollOver}
            onMouseLeave={handleRollOut}
            onClick={handleClick}
          >
            <Pilowlava />
            <Newsreader />
            <NanJaune />
            <IvyPrestoDisplay />
            <AfricaRainfall />
            <Beastly />
            <Fadoli />
            <SwearDisplay />
            <Pilowlava />
          </Link>
        </FontSliderContainer>
      </FontSliderWrapper>
      <FontLinkButton ref={fontLinkButtonRef}>
        <Link
          href={fontsLinks[counter]}
          prefetch={false}
          onClick={handleClick}
        >
          <Icon label="right" />
        </Link>
      </FontLinkButton>
    </FontSliderStyled>
  );
};

export default HomeHeadlineSlider;
