import { AfricaRainfallWrapper } from './AfricaRainfall.styled';

const AfricaRainfall = () => {
  return (
    <AfricaRainfallWrapper>
      <svg
        width="567"
        height="75"
        viewBox="0 0 567 75"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5314_142580)">
          <rect
            width="567"
            height="75"
            fill="transparent"
          />
          <path
            d="M19.0449 70.5251C28.3169 70.5251 33.7459 64.5471 38.2599 58.2031C39.9069 67.4752 46.6779 70.4641 54.5469 70.4641C62.4769 70.4641 68.8209 66.9261 74.3719 60.0941L73.7009 59.3011C71.3829 60.8871 69.6749 61.3751 67.7229 61.3751C64.7949 61.3751 63.3309 59.4841 63.4529 54.4821L64.0019 28.9231C64.3679 11.4161 53.8149 4.09614 34.1729 4.09614C19.5329 4.09614 3.48994 11.5381 3.48994 21.5421C3.48994 26.5441 7.51594 29.1671 12.6399 29.1671C20.9969 29.1671 23.6809 24.4701 24.7179 16.9671C25.7549 9.46414 28.9879 6.59714 33.7459 6.59714C38.3819 6.59714 40.6389 9.95214 40.6389 14.7101C40.6389 19.6511 38.5649 24.5921 28.8659 29.0451C12.9449 36.3041 0.439941 40.5131 0.439941 53.0181C0.439941 64.1201 9.04094 70.5251 19.0449 70.5251ZM38.0159 56.7391C36.9179 56.9831 35.8809 57.1051 34.9049 57.1051C29.1709 57.1051 26.3039 52.5301 26.3039 46.4301C26.3039 39.6591 28.1949 34.9621 38.5039 25.7511L38.0159 56.7391Z"
            fill="#9FE5C8"
          />
          <path
            d="M138.585 73.2701H139.012L155.482 32.9491C163.961 12.1481 167.499 8.36614 177.93 6.47514V5.43814H138.28V6.47514C147.369 7.45114 159.569 15.0151 152.493 32.5831L149.199 40.6961L126.568 4.21814L112.172 36.9141L102.351 14.5271C99.9719 9.15914 99.7889 7.63414 108.329 6.47514V5.43814H63.7989V6.47514C69.5939 8.12214 70.2039 9.09814 72.4609 14.2831L98.0809 73.2701H98.5079L116.259 34.8401L138.585 73.2701Z"
            fill="#9FE5C8"
          />
          <path
            d="M234.627 40.8791C229.808 47.1011 222 50.0901 212.85 50.0901C197.478 50.0901 186.986 39.7201 184.729 27.3371H232.492C229.259 14.8931 219.194 4.21814 202.419 4.21814C186.132 4.21814 168.076 16.9671 168.076 38.7441C168.076 59.3621 183.021 70.5251 200.406 70.5251C219.133 70.5251 228.832 58.6911 235.298 41.1841L234.627 40.8791ZM198.454 7.51214C203.761 7.51214 208.275 9.76914 208.275 15.7471C208.275 22.9451 203.273 24.4701 193.208 24.4701H184.363C184.302 23.6771 184.241 22.9451 184.241 22.1521C184.241 11.9041 191.134 7.51214 198.454 7.51214Z"
            fill="#9FE5C8"
          />
          <path
            d="M269.982 67.7191C260.771 67.7191 249.12 61.6801 241.129 43.9291H239.848L244.667 74.0021H245.765C246.619 69.4271 248.083 67.7801 250.95 67.7801C254.854 67.7801 259.734 70.5251 269.25 70.5251C285.72 70.5251 298.591 63.3271 298.591 47.1011C298.591 36.2431 291.759 29.4111 276.57 25.5071L270.226 23.8601C262.784 21.9691 258.392 19.4071 258.392 14.1611C258.392 9.76914 261.991 7.08514 267.603 7.08514C275.96 7.08514 286.879 12.8191 295.053 28.9841H296.273L289.746 0.741138H288.709C287.855 5.31614 286.391 6.96314 283.829 6.96314C280.169 6.96314 276.875 4.21814 267.847 4.21814C254.183 4.21814 239.665 11.4161 239.665 26.3001C239.665 37.8291 247.046 46.4911 261.442 50.2121L267.481 51.7371C276.143 53.9941 279.315 56.1901 279.315 60.8261C279.315 64.7301 276.326 67.7191 269.982 67.7191Z"
            fill="#9FE5C8"
          />
          <path
            d="M344.857 70.3421C367.671 67.9021 381.396 50.5171 379.566 32.8881C377.858 16.5401 362.974 2.75414 342.6 2.75414C341.014 2.75414 339.428 2.81514 337.781 2.99814C314.967 5.37714 301.181 22.7621 303.011 40.4521C304.719 56.8001 319.664 70.5861 340.038 70.5861C341.624 70.5861 343.21 70.5251 344.857 70.3421ZM344.613 67.6581C337.354 68.3901 333.572 60.5821 331.193 37.4631C328.753 14.3441 330.644 6.41414 337.964 5.68214C345.223 4.88914 349.005 12.7581 351.445 35.8161C353.824 58.9351 351.933 66.8651 344.613 67.6581Z"
            fill="#9FE5C8"
          />
          <path
            d="M421.278 69.0001H458.732V67.9631C453.974 64.9741 453.12 61.3751 453.12 47.7111V23.6161C453.12 21.8471 452.998 20.2001 452.754 18.6751L452.998 19.0411C454.767 18.3091 456.17 18.1871 457.512 18.1871C461.843 18.1871 465.32 19.5291 465.32 29.4111V47.7111C465.32 61.3751 465.076 64.9741 460.318 67.9631V69.0001H497.772V67.9631C493.014 64.9741 492.16 61.3751 492.16 47.7111V23.6161C492.16 10.1961 484.84 4.21814 475.019 4.21814C465.32 4.21814 457.695 10.1961 452.571 17.8211C450.741 8.48814 444.275 4.21814 435.979 4.21814C426.585 4.21814 419.204 9.83014 414.08 17.0891V0.619141H413.043C405.967 8.79314 394.743 12.2091 381.628 12.6361V13.6731C386.386 16.6621 387.24 20.2611 387.24 33.9251V47.7111C387.24 61.3751 386.386 64.9741 381.628 67.9631V69.0001H419.082V67.9631C414.324 64.9741 414.08 61.3751 414.08 47.7111V18.9801C415.788 18.3091 417.191 18.1871 418.472 18.1871C422.803 18.1871 426.28 19.5291 426.28 29.4111V47.7111C426.28 61.3751 426.036 64.9741 421.278 67.9631V69.0001Z"
            fill="#9FE5C8"
          />
          <path
            d="M565.49 40.8791C560.671 47.1011 552.863 50.0901 543.713 50.0901C528.341 50.0901 517.849 39.7201 515.592 27.3371H563.355C560.122 14.8931 550.057 4.21814 533.282 4.21814C516.995 4.21814 498.939 16.9671 498.939 38.7441C498.939 59.3621 513.884 70.5251 531.269 70.5251C549.996 70.5251 559.695 58.6911 566.161 41.1841L565.49 40.8791ZM529.317 7.51214C534.624 7.51214 539.138 9.76914 539.138 15.7471C539.138 22.9451 534.136 24.4701 524.071 24.4701H515.226C515.165 23.6771 515.104 22.9451 515.104 22.1521C515.104 11.9041 521.997 7.51214 529.317 7.51214Z"
            fill="#9FE5C8"
          />
        </g>
        <defs>
          <clipPath id="clip0_5314_142580">
            <rect
              width="567"
              height="75"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </AfricaRainfallWrapper>
  );
};

export default AfricaRainfall;
