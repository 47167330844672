import styled from '@emotion/styled';
import { mq } from 'styles/mediaqueries.styled';

export const FadoliWrapper = styled.div`
  position: relative;
  display: flex;
  /* MOBILE */
  height: 6.25vw;
  /* DESKTOP_1440 */
  ${mq['desktop_1440']} {
    height: 90px;
  }

  svg {
    /* MOBILE */
    width: 38.827vw;
    height: 4.784vw;
    /* TABLET */
    ${mq['tablet']} {
      width: 38.889vw;
      height: 4.792vw;
    }
    /* DESKTOP_1440 */
    ${mq['desktop_1440']} {
      width: 560px;
      height: 69px;
    }
  }
`;
