import styled from '@emotion/styled';
import { mq } from 'styles/mediaqueries.styled';

export const AfricaRainfallWrapper = styled.div`
  position: relative;
  display: flex;
  /* MOBILE */
  height: 6.25vw;
  /* DESKTOP_1440 */
  ${mq['desktop_1440']} {
    height: 90px;
  }

  svg {
    /* MOBILE */
    width: 39.312vw;
    height: 5.2vw;
    /* TABLET */
    ${mq['tablet']} {
      width: 39.375vw;
      height: 5.208vw;
    }
    /* DESKTOP_1440 */
    ${mq['desktop_1440']} {
      width: 567px;
      height: 75px;
    }
  }
`;
