import styled from '@emotion/styled';
import { mq } from 'styles/mediaqueries.styled';

export const IvyPrestoDisplayWrapper = styled.div`
  position: relative;
  display: flex;
  /* MOBILE */
  height: 6.25vw;
  /* DESKTOP_1440 */
  ${mq['desktop_1440']} {
    height: 90px;
  }

  svg {
    /* MOBILE */
    width: 34.875vw;
    height: 4.715vw;
    /* TABLET */
    ${mq['tablet']} {
      width: 34.931vw;
      height: 4.722vw;
    }
    /* DESKTOP_1440 */
    ${mq['desktop_1440']} {
      width: 503px;
      height: 68px;
    }
  }
`;
