import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { dehydrate, InfiniteData, QueryClient, useQueryClient } from '@tanstack/react-query';
import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Icon } from 'design-system/atoms/icon';
// import { DropdownBig } from 'design-system/atoms/dropdown-big';
import { trackHomePage } from 'tracking/Mixpanel';
import { oTelCountPage } from 'tracking/OpenTelemetry';
import { useAuthContext, useBreakpointContext, useSettingsContext } from 'context';
import { useDebounce, useFixScroll, usePrevious } from 'hooks';
import { useFamilySearch } from 'hooks/queries/useFamilySearch';
import api, { injectXNinjaSsrIp } from 'api';
import { Display, Filters, Sorting } from 'api/familySearch.api';
import Card from 'containers/Card';
import OptionsBar from 'containers/OptionsBar';
import HomeHeadlineSlider from 'components/HomeHeadlineSlider';
import Spinner from 'components/Spinner';
import SmileySad from 'components/SmileySad';
import {
  generateRandomSeed,
  getCanonicalUrl,
  getCurrentFilters,
  getHostnameUrl,
  getInitialFilters,
} from 'common/utils';
import { initialCurrency, initialDisplayedWeight, initialSearchValue } from 'common/data/Settings';
import {
  BIG_DESKTOP_GUTTER,
  DESKTOP,
  DESKTOP_1200,
  DESKTOP_CARD_GAP,
  DESKTOP_CARD_WIDTH,
  DESKTOP_GUTTER,
  DESKTOP_MIN_CARD_HEIGHT,
  HOME_PAGE,
  MOBILE,
  MOBILE_CARD_WIDTH,
  MOBILE_GUTTER,
  MOBILE_MIN_CARD_HEIGHT,
  NAV_GLOBAL_HEIGHT,
  PAGE_SIZE,
  QUERY_KEYS,
  SCROLL_THRESHOLD,
  TABLET,
  TABLET_CARD_GAP,
  TABLET_CARD_WIDTH,
  TABLET_GUTTER,
} from 'common/data/Constants';
import {
  // DropdownContainer,
  EmptyFamilyListText,
  EmptyFamilyListWrapper,
  FamilyListContainer,
  FamilyListLoading,
  FamilyListWrapper,
  Headline,
  HomeWrapper,
} from 'styles/home.styled';

const Home: NextPage = ({
  seed,
  initialFilters,
  initialSorting,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { t } = useTranslation('home');
  const { isLoggedIn, preferredCurrency } = useAuthContext();
  const { breakpoint, isMobile } = useBreakpointContext();
  const {
    contrast,
    darkMode,
    displayOptionsBarFilters,
    displayedWeight,
    ending,
    fontStyles,
    minStyles,
    priceRange,
    trialFonts,
    monospacedFonts,
    width,
  } = useSettingsContext();

  const router = useRouter();

  const selectedCurrency = useMemo(() => preferredCurrency ?? initialCurrency, [preferredCurrency]);

  const debounceDisplayedWeight = parseInt(useDebounce(displayedWeight.toString(), 250));
  const previousDisplayedWeight = usePrevious(debounceDisplayedWeight);

  const dropdownOptions: Options = useMemo(() => {
    const options = [
      { value: 'latest_bookmark', label: t('dropdown.lastest-bookmarks') },
      { value: 'foundries', label: t('dropdown.by-foundries') },
      { value: 'popularity_websites', label: t('dropdown.popularity-use') },
      { value: 'popularity_bookmarks', label: t('dropdown.popularity-bookmark') },
      { value: 'random', label: t('dropdown.random') },
    ];
    if (isLoggedIn) {
      options.splice(1, 0, { value: 'community', label: t('dropdown.your-community') });
    }
    return options;
  }, [isLoggedIn, t]);

  const [sorting, setSorting] = useState<Sorting>(
    (dropdownOptions.find((option) => option.value === 'random') ?? dropdownOptions[0]).value as Sorting
  );
  const [familySearchState, setFamilySearchState] = useState<
    InfiniteData<API.FamilySearch.ResultFamilySearch> | undefined
  >(undefined);
  const [scaleCardValue, setScaleCardValue] = useState<number>(1);
  const [showOptionsBar, setShowOptionsBar] = useState<boolean>(false);
  const [displayedWeightLoading, setDisplayedWeightLoading] = useState<boolean>(false);
  const [forceLoadingCard, setForceLoadingCard] = useState<boolean>(false);

  const filters: Filters = useMemo(
    () =>
      getCurrentFilters(
        initialSearchValue,
        minStyles,
        contrast,
        width,
        ending,
        fontStyles,
        trialFonts,
        monospacedFonts,
        priceRange,
        selectedCurrency
      ),
    [contrast, ending, fontStyles, minStyles, monospacedFonts, priceRange, selectedCurrency, trialFonts, width]
  );
  const previousFilters = usePrevious(filters);

  const display: Display = useMemo(
    () => ({
      bestWeight: displayedWeight,
    }),
    [displayedWeight]
  );

  const queryClient = useQueryClient();

  const randomSeed = sorting === 'random' ? seed : undefined;

  // Get serverSide data to display cards in all cases
  // if currency or filters in options bar are not the same
  const familySearchServerSideData: InfiniteData<API.FamilySearch.ResultFamilySearch> | undefined =
    queryClient.getQueryData([
      QUERY_KEYS.FAMILY_SEARCH,
      { filters: initialFilters, sorting: initialSorting, seed: randomSeed },
    ]);

  const {
    data: familySearchData,
    isStale: familySearchStale,
    isLoading: familySearchLoading,
    isFetching: familySearchFetching,
    isSuccess: familySearchSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useFamilySearch(PAGE_SIZE, filters, display, sorting, randomSeed);

  // if `familySearchServerSideData` exists
  if (familySearchServerSideData) {
    if (!familySearchData && !familySearchState) {
      // `familySearchData` and `familySearchState` are undefined
      // set data from serverSide inside `familySearchState`
      // and set idSearch = '' to avoid to call `continueFamilySearch`
      // and set forceLoadingCard to true
      familySearchServerSideData.pages[0].idSearch = '';
      setFamilySearchState(familySearchServerSideData);
      setForceLoadingCard(true);
    } else if (familySearchData && !familySearchState) {
      // `familySearchData` exists but `familySearchState` is undefined
      // set data from serverSide inside `familySearchState`
      setFamilySearchState(familySearchServerSideData);
    }
  }

  useEffect(() => {
    if (familySearchSuccess && forceLoadingCard) {
      // setTimeout to avoid 2 cards flick when change families
      setTimeout(() => {
        setForceLoadingCard(false);
      }, 100);
    }
  }, [familySearchSuccess, forceLoadingCard]);

  useEffect(() => {
    if (familySearchData) {
      setFamilySearchState(familySearchData);
    }
  }, [familySearchData]);

  // Request and update query data when user change displayedWeight in OptionsBar
  useEffect(() => {
    if (
      familySearchState &&
      previousDisplayedWeight !== undefined &&
      debounceDisplayedWeight !== previousDisplayedWeight
    ) {
      // Case we force idSearch='', we do nothing
      if (familySearchState.pages[0].idSearch === '') {
        return;
      }
      setDisplayedWeightLoading(true);
      const pageLength = familySearchState.pages.length;
      const cloneFamilySearchState: InfiniteData<API.FamilySearch.ResultFamilySearch> = JSON.parse(
        JSON.stringify(familySearchState)
      );
      for (let i = 1; i <= pageLength; i++) {
        api.familySearch
          .continueFamilySearch(familySearchState.pages[0].idSearch, PAGE_SIZE, i, {
            bestWeight: debounceDisplayedWeight,
          })
          .then((data) => {
            cloneFamilySearchState.pages[i - 1].families = data.families;
            cloneFamilySearchState.pages[i - 1].totalFamilies = data.totalFamilies;
            queryClient.setQueryData(
              [QUERY_KEYS.FAMILY_SEARCH, { filters, sorting, seed: randomSeed }],
              cloneFamilySearchState
            );
            setFamilySearchState(cloneFamilySearchState);
            setDisplayedWeightLoading(false);
          });
      }
    }
  }, [debounceDisplayedWeight, familySearchState, filters, previousDisplayedWeight, queryClient, randomSeed, sorting]);

  const familySearchList = useMemo(() => familySearchState ?? familySearchData, [familySearchData, familySearchState]);

  const familyListWrapperRef = useRef<HTMLDivElement>(null);
  const cardHeightRef = useRef<number>();

  if (previousFilters && JSON.stringify(filters) !== JSON.stringify(previousFilters) && familyListWrapperRef.current) {
    if (familySearchStale && !forceLoadingCard) {
      setForceLoadingCard(true);
    }
    if (window.scrollY > familyListWrapperRef.current.getBoundingClientRect().top + window.scrollY) {
      const y = familyListWrapperRef.current.getBoundingClientRect().top + window.scrollY - NAV_GLOBAL_HEIGHT;
      window.scrollTo({ top: y });
    }
  }

  const handleResize = useCallback(() => {
    if (familyListWrapperRef.current) {
      let contentWidth: number;
      let maxContentWidth: number;
      let scaleValue: number = 1;
      if (breakpoint === MOBILE) {
        contentWidth = window.innerWidth - 2 * MOBILE_GUTTER;
        maxContentWidth = MOBILE_CARD_WIDTH;
        scaleValue = contentWidth / maxContentWidth;
      } else if (breakpoint === TABLET) {
        contentWidth = window.innerWidth - 2 * TABLET_GUTTER;
        maxContentWidth = 2 * TABLET_CARD_WIDTH + TABLET_CARD_GAP;
        scaleValue = contentWidth / maxContentWidth;
      } else if (breakpoint === DESKTOP) {
        contentWidth = window.innerWidth - 2 * DESKTOP_GUTTER;
        maxContentWidth = 3 * DESKTOP_CARD_WIDTH + 2 * DESKTOP_CARD_GAP;
        scaleValue = contentWidth / maxContentWidth;
      } else if (breakpoint === DESKTOP_1200) {
        contentWidth = window.innerWidth - 2 * BIG_DESKTOP_GUTTER;
        maxContentWidth = 3 * DESKTOP_CARD_WIDTH + 2 * DESKTOP_CARD_GAP;
        scaleValue = contentWidth / maxContentWidth;
      }
      setScaleCardValue(scaleValue);
    }
  }, [breakpoint]);

  const handleScroll = useCallback(() => {
    if (familyListWrapperRef.current) {
      const halfCardHeight =
        cardHeightRef.current ?? (isMobile ? MOBILE_MIN_CARD_HEIGHT >> 1 : DESKTOP_MIN_CARD_HEIGHT >> 1);
      if (familyListWrapperRef.current.getBoundingClientRect().top < window.innerHeight - halfCardHeight) {
        if (!showOptionsBar) {
          setShowOptionsBar(true);
        }
      } else {
        if (showOptionsBar) {
          setShowOptionsBar(false);
        }
      }
    }
  }, [isMobile, showOptionsBar]);

  // DISABLE FOR BETA
  // const handleDropdownChange = useCallback((option: any) => {
  //   const selectedOption = option as Option;
  //   setSorting(selectedOption.value as Sorting);
  // }, []);

  const handleRetrieveCardHeight = useCallback((cardHeight: number) => {
    cardHeightRef.current = cardHeight;
  }, []);

  // Track Mixpanel page view
  useEffect(() => {
    trackHomePage(0);
  }, [router.query]);

  // Count OpenTelemetry page view
  useEffect(() => {
    oTelCountPage(`/${HOME_PAGE}`.toLowerCase());
  }, []);

  useEffect(() => {
    handleResize();
    handleScroll();
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleResize, handleScroll]);

  // Fix infinite scrolling when there is less results than page height
  // or when page is scrolled to the bottom
  useFixScroll(!!hasNextPage, fetchNextPage, SCROLL_THRESHOLD);

  return (
    <>
      <Head>
        <title>{t('head.title')}</title>
        <meta
          name="description"
          content={t('head.description')}
        />
        <meta
          name="keywords"
          content={t('head.keywords')}
        />
        <meta
          property="og:title"
          content={t('head.title')}
        />
        <meta
          property="og:description"
          content={t('head.description')}
        />
        <meta
          property="og:image"
          content={`${getHostnameUrl()}${t('head.image')}`}
        />
        <meta
          property="og:image:alt"
          content={t('head.image-alt')}
        />
        <meta
          property="og:url"
          content={getCanonicalUrl(router.asPath)}
        />
        <meta
          property="og:type"
          content="article"
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
        />
        <meta
          name="twitter:site"
          content={t('head.twitter-site')}
        />
        <meta
          name="twitter:title"
          content={t('head.title')}
        />
        <meta
          name="twitter:description"
          content={t('head.description')}
        />
        <meta
          name="twitter:image"
          content={`${getHostnameUrl()}${t('head.image')}`}
        />
        <meta
          name="twitter:image:alt"
          content={t('head.image-alt')}
        />
        <link
          rel="canonical"
          href={getCanonicalUrl(router.asPath)}
          key="canonical"
        />
      </Head>

      <HomeWrapper>
        <Headline>
          <Icon label="world" />
          {' '}
          {t('headline.discover')} <span>{t('headline.awesome')}</span>
          <HomeHeadlineSlider />
          {t('headline.typefaces')} <Icon label="font_folder" />
          {' '}
          {t('headline.font-collection')}
        </Headline>

        <FamilyListContainer>
          {/* DISABLE FOR BETA */}
          {/* <DropdownContainer darkMode={darkMode}>
            <DropdownBig
              darkMode={darkMode}
              placeholder={t('dropdown.select')}
              noOptionsMessage={() => t('dropdown.no-options')}
              isSearchable={false}
              options={dropdownOptions}
              defaultValue={dropdownOptions.find((option) => option.value === 'random') ?? dropdownOptions[0]}
              width={isMobile ? '270px' : '347px'}
              optionsWidth={isMobile ? '334px' : '406px'}
              onChange={handleDropdownChange}
            />
            <Spinner
              darkMode={darkMode}
              className={familySearchLoading || displayedWeightLoading ? `spinner spinner-visible` : `spinner`}
            />
          </DropdownContainer> */}
          <FamilyListWrapper
            ref={familyListWrapperRef}
            scaleValue={scaleCardValue}
          >
            {/* No results */}
            {familySearchList && familySearchList.pages[0].totalFamilies === 0 && (
              <EmptyFamilyListWrapper>
                <SmileySad darkMode={darkMode} />
                <EmptyFamilyListText
                  dangerouslySetInnerHTML={{ __html: t('search-result.no-result', { ns: 'common' }) }}
                />
              </EmptyFamilyListWrapper>
            )}
            {/* Results */}
            {familySearchList && familySearchList.pages[0].totalFamilies > 0 && (
              <div id="scroll-items-container">
                <InfiniteScroll
                  scrollThreshold={`${SCROLL_THRESHOLD}px`}
                  dataLength={familySearchList.pages.reduce((acc, page) => acc + page.families.length, 0)}
                  next={() => {
                    if (!isFetchingNextPage) {
                      fetchNextPage();
                      trackHomePage(familySearchList.pages.length);
                    }
                  }}
                  hasMore={!!hasNextPage}
                  loader={null}
                >
                  {familySearchList.pages.map((page, pageIndex) => (
                    <Fragment key={pageIndex}>
                      {page.families.map((family, index) => (
                        <div key={`${pageIndex}_${index}`}>
                          <Card
                            index={pageIndex * PAGE_SIZE + index}
                            family={family}
                            page={pageIndex}
                            scaleValue={scaleCardValue}
                            forceLoading={forceLoadingCard}
                            source="home"
                            onCardHeight={handleRetrieveCardHeight}
                          />
                        </div>
                      ))}
                    </Fragment>
                  ))}
                </InfiniteScroll>
                {familySearchFetching && familySearchList.pages[0].totalFamilies > PAGE_SIZE && (
                  <FamilyListLoading>
                    <Spinner darkMode={darkMode} />
                  </FamilyListLoading>
                )}
              </div>
            )}
          </FamilyListWrapper>
        </FamilyListContainer>
      </HomeWrapper>

      <OptionsBar show={showOptionsBar} />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  // Inject x-frorwarded-for inside headers to retrieve user ip address
  const forwarded = context.req.headers['x-forwarded-for'];
  const ip = typeof forwarded === 'string' ? forwarded.split(/, /)[0] : context.req.socket.remoteAddress;
  ip && injectXNinjaSsrIp(ip);

  const seed = generateRandomSeed(5);
  const filters = getInitialFilters();
  const display = { bestWeight: initialDisplayedWeight };
  const sorting = 'random';
  const randomSeed = sorting === 'random' ? seed : undefined;

  const queryClient = new QueryClient();

  await Promise.all([
    queryClient.prefetchInfiniteQuery([QUERY_KEYS.FAMILY_SEARCH, { filters, sorting, seed: randomSeed }], () =>
      api.familySearch.initFamilySearch(PAGE_SIZE, 1, filters, display, sorting, randomSeed)
    ),
  ]);

  return {
    props: {
      ...(await serverSideTranslations(context.locale ?? 'en', ['common', 'nav', 'home'])),
      seed: seed,
      initialFilters: filters,
      initialSorting: sorting,
      dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
    },
  };
};

export default Home;
